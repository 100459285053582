import {createFormActionTypes} from "@pg-mono/form";
import {createRequestActionTypes} from "@pg-mono/request-state";

import {IRoomFilterFormValues} from "../components/PropertyDetailOtherPropertiesFilters";

const UPDATE_OTHER_PROPERTIES_FILTER_FORM = `property_detail/UPDATE_OTHER_PROPERTIES_FILTER_FORM`;
export const otherPropertiesFilterFormTypes = createFormActionTypes(UPDATE_OTHER_PROPERTIES_FILTER_FORM);
export const otherPropertiesFilterFormRequestTypes = createRequestActionTypes(UPDATE_OTHER_PROPERTIES_FILTER_FORM);

export const updateOtherPropertiesFilterAction = (formValues: Partial<IRoomFilterFormValues>) => ({
    type: otherPropertiesFilterFormTypes.update,
    formValues
});
import {createFormActionTypes} from "@pg-mono/form";
import {createRequestActionTypes} from "@pg-mono/request-state";

import {IRoomFilterFormValues} from "../components/PropertyDetailOtherPropertiesFilters";

const UPDATE_OTHER_PROPERTIES_FILTER_FORM = `property_detail/UPDATE_OTHER_PROPERTIES_FILTER_FORM`;
export const otherPropertiesFilterFormTypes = createFormActionTypes(UPDATE_OTHER_PROPERTIES_FILTER_FORM);
export const otherPropertiesFilterFormRequestTypes = createRequestActionTypes(UPDATE_OTHER_PROPERTIES_FILTER_FORM);

export const updateOtherPropertiesFilterAction = (formValues: Partial<IRoomFilterFormValues>) => ({
    type: otherPropertiesFilterFormTypes.update,
    formValues
});
